<template>
  <div id="top">
    <v-app id="home"> 
      <navbar :pageIn="0"/>

      <v-btn class="float" rounded icon @click="$vuetify.goTo('#top')">
        <v-icon> mdi-arrow-up </v-icon>
      </v-btn>
      
      <v-main>
        <section id="hero">
          <v-row no-gutters>
            <v-img
              :min-height="'calc(100vh - ' + $vuetify.application.top + 'px)'"
              src="https://firebasestorage.googleapis.com/v0/b/pedreragarden.appspot.com/o/Media%2FAssets%2Fhome-img.JPG?alt=media&token=f1ca3946-2f02-4a03-bd42-825c10cb8d49"
            >
              <!-- src="https://images.unsplash.com/photo-1487017159836-4e23ece2e4cf?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1951&q=80" -->
              <v-theme-provider dark>
                <v-container fill-height>
                  <v-row
                    align="center"
                    class="white--text mx-auto"
                    justify="center"
                  >
                    <v-col class="white--text text-center" cols="12" tag="h1">
                      <span
                        :class="[
                          $vuetify.breakpoint.smAndDown
                            ? 'display-1'
                            : 'display-2',
                        ]"
                        class="font-weight-light"
                      >
                        CONOCE
                      </span>

                      <br />

                      <span
                        :class="[
                          $vuetify.breakpoint.smAndDown
                            ? 'display-3'
                            : 'display-4',
                        ]"
                        class="font-weight-black"
                      >
                        JARDÍN LA PEDRERA
                      </span>
                    </v-col>

                    <v-btn
                      class="align-self-end"
                      fab
                      outlined
                      @click="$vuetify.goTo('#about-me')"
                    >
                      <v-icon>mdi-chevron-double-down</v-icon>
                    </v-btn>
                  </v-row>
                </v-container>
              </v-theme-provider>
            </v-img>
          </v-row>
        </section>

        <section id="about-me">
          <div class="py-12"></div>

          <v-container class="text-center">
            <!-- ABOUT US COLUM -->
            <v-row>
              <v-col cols="12" sm="6" class="mb-10">
                <h2 class="display-2 font-weight-bold mb-3">¿QUIÉNES SOMOS?</h2>
                <v-responsive class="mx-auto mb-8" width="56">
                  <v-divider class="mb-1"></v-divider>

                  <v-divider></v-divider>
                </v-responsive>

                <v-responsive
                  class="mx-auto title font-weight-light mb-8 descritive-text"
                  max-width="720"
                >
                  Jardín La Pedrera es un sitio electrónico para la  contratación de eventos infantiles en línea. Con apoyo de la tecnología, te facilitamos la planeación, el diseño y el pago de tu evento desde la comodidad de tu casa.
                </v-responsive>

                <v-avatar class="elevation-12 mb-12" contain size="180">
                  <v-img src="https://firebasestorage.googleapis.com/v0/b/pedreragarden.appspot.com/o/Media%2FAssets%2FlogoPNG.png?alt=media&token=909a287d-504f-4b15-8b06-63a9e1ec90a4"></v-img>
                </v-avatar>

                <div></div>
                <v-btn :color="colors.mainButton"  outlined large @click="tryReservation()">
                  <span class="font-weight-bold" > ¡Reserva tu Evento! </span>
                </v-btn>
              </v-col>
              <!-- LOCATION COLUM -->
              <v-col cols="12" sm="6">
                <h2 class="display-2 font-weight-bold mb-3">
                  NUESTRA UBICACIÓN
                </h2>
                <v-responsive class="mx-auto mb-8" width="56">
                  <v-divider class="mb-1"></v-divider>

                  <v-divider></v-divider>
                </v-responsive>

                <v-responsive
                  class="mx-auto title font-weight-light mb-8 location-text"
                  max-width="720"
                >
                </v-responsive>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d470.13994848622286!2d-96.8163798298264!3d19.493490091762087!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xf2259cd4ad668b!2sLa%20Pedrera%20Garden!5e0!3m2!1sen!2smx!4v1643658801851!5m2!1sen!2smx"
                  style="border: 0"
                  allowfullscreen=""
                  loading="lazy"
                  class="google-map"
                >
                </iframe>
              </v-col>
            </v-row>
          </v-container>
          <div class="py-2"></div>
          <v-btn
            class="align-self-end hidden-sm-and-down"
            fab
            outlined
            @click="$vuetify.goTo('#stats')"
          >
            <v-icon>mdi-chevron-double-down</v-icon>
          </v-btn>
          <div class="py-10 hidden-sm-and-down"></div>
        </section>
        <!--
        <section id="features" class="grey lighten-3">
          <div class="py-12"></div>

          <v-container class="text-center">
            <h2 class="display-2 font-weight-bold mb-3">¿QUÉ OFRECEMOS?</h2>

            <v-responsive class="mx-auto mb-12" width="56">
              <v-divider class="mb-1"></v-divider>

              <v-divider></v-divider>
            </v-responsive>

            <v-row>
              <v-col
                v-for="({ icon, title, text }, i) in features"
                :key="i"
                cols="12"
                md="4"
              >
                <v-card class="py-12 px-4" color="grey lighten-5" flat>
                  <v-theme-provider dark>
                    <div>
                      <v-avatar :color="colors.avatarColors" size="88">
                        <v-icon large v-text="icon"></v-icon>
                      </v-avatar>
                    </div>
                  </v-theme-provider>

                  <v-card-title
                    class="justify-center font-weight-black text-uppercase"
                    v-text="title"
                  ></v-card-title>

                  <v-card-text class="subtitle-1" v-text="text"> </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>

          <div class="py-2"></div>
          <v-btn
            class="align-self-end"
            fab
            outlined
            @click="$vuetify.goTo('#gallery')"
          >
            <v-icon>mdi-chevron-double-down</v-icon>
          </v-btn>
          <div class="py-10"></div>
        </section>

        <h2 class="display-2 font-weight-bold mt-10">¿QUÉ OFRECEMOS?</h2>
        -->

        <section id="stats" class="hidden-sm-and-down">
          <div class="py-8"></div>
          <v-parallax
            class="py-10"
            :height="$vuetify.breakpoint.smAndDown ? 700 : 800"
            src="https://firebasestorage.googleapis.com/v0/b/pedreragarden.appspot.com/o/Media%2FJardin%2Fbackground-home.jpeg?alt=media&token=eed764a6-af91-435f-8821-4c4e66d6cdef"
          >
            <v-container fill-height class="text-center">
              <v-row>
                <v-col
                  v-for="({ icon, title, text }, i) in features"
                  :key="i"
                  cols="12"
                  md="4"
                >
                  <v-card class="py-12 px-4" color="grey lighten-5" flat>
                    <v-theme-provider dark>
                      <div>
                        <v-avatar :color="colors.avatarColors" size="88">
                          <v-icon large v-text="icon"></v-icon>
                        </v-avatar>
                      </div>
                    </v-theme-provider>

                    <v-card-title
                      class="justify-center font-weight-black text-uppercase"
                      v-text="title"
                    ></v-card-title>

                    <v-card-text class="subtitle-1" v-text="text">
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
            <v-btn
              class="align-self-center"
              fab
              outlined
              elevation="10"
              @click="$vuetify.goTo('#gallery')"
            >
              <v-icon>mdi-chevron-double-down</v-icon>
            </v-btn>
          </v-parallax>
        </section>

        <section id="gallery">
          <v-container>
            <h2
              class="display-2 font-weight-bold mb-3 text-uppercase text-center"
            >
              Galería
            </h2>

            <v-responsive class="mx-auto mb-12" width="56">
              <v-divider class="mb-1"></v-divider>

              <v-divider></v-divider>
            </v-responsive>
            <Gallery v-bind:images="images" />
             <div class="py-2"></div>
              <v-btn
                class="align-self-end hidden-sm-and-down"
                fab
                outlined
                @click="$vuetify.goTo('#citas')"
              >
                <v-icon>mdi-chevron-double-down</v-icon>
              </v-btn>
              <div class="py-10 hidden-sm-and-down"></div>
          </v-container>

          <div class="py-12"></div>
        </section>

        <section id="citas">
          <div class="py-8"></div>

          <v-container>
            <h2
              class="display-2 font-weight-bold mb-3 text-uppercase text-center"
            >
              VISÍTANOS!
            </h2>

            <v-responsive class="mx-auto mb-12" width="56">
              <v-divider class="mb-1"></v-divider>
              <v-divider></v-divider>
            </v-responsive>
            <v-row class=" mt-2 px-15">
              <v-col cols="12" md="6">
                <h1 class="first-line">¿Quieres visitar las instalaciones?</h1>
                Agenda una cita, tenemos un horario de atencion de lunes a viernes de 9am a 5pm 
                <template>
                  <form>
                    <v-row justify="center">
                      <v-col cols="12" md="6" class="pt-10"> 
                        <v-text-field
                          v-model="citaNombre"
                          label="Nombres*"
                          required
                        ></v-text-field>
                        <v-text-field
                          v-model="citaApellidos"
                          label="Apellidos*"
                          required
                        ></v-text-field>
                        <v-text-field
                          v-model="citaNumeroTelefono"
                          label="Numero Telefonico*"
                          required
                        ></v-text-field>
                        <v-datetime-picker label="Fecha y Hora*" :datePickerProps="datepickerSettings" :timePickerProps="timepickerSettings" v-model="formattedDatetime" date-format="yyyy-MM-dd-">
                        <template slot="dateIcon">
                          <v-icon>mdi-calendar-question</v-icon>
                        </template>
                        <template slot="timeIcon">
                          <v-icon>mdi-clock-time-four</v-icon>
                        </template>
                        <template slot="actions" slot-scope="{ parent }">
                          <v-btn color="error lighten-1" @click.native="parent.clearHandler">Cancelar</v-btn>
                          <v-btn color="success darken-1" @click="parent.okHandler">Listo!</v-btn>
                        </template>
                        </v-datetime-picker>
                      </v-col>
                    </v-row>
                    <v-btn @click="makeCita()" outlined color="green">
                      Agendar Cita!
                    </v-btn>
                  </form>
                </template>
              </v-col>
              <v-col cols="12" md="6">
                <v-col cols="12">
                  <v-sheet height="500">
                    <v-calendar
                      ref="calendar"
                      v-model="calendarValue"
                      type="month"
                      :events="events"
                      :event-overlap-mode="mode"
                      :event-overlap-threshold="60"
                      :event-color="getEventColor"
                      @change="getEvents"
                      locale="es"
                    ></v-calendar>
                  </v-sheet>
                </v-col>
              </v-col>
            </v-row>
          </v-container>

          <div class="py-12"></div>
        </section>

    


        <FooterOne />       
      </v-main>

      <template>
      <v-row justify="center">
        <v-dialog
          v-model="noUserDialog"
          persistent
          max-width="290"
        >
          <v-card>
            <v-card-title class="justify-center text-h5 mb-2">
              NO TAN RAPIDO
            </v-card-title>
              <v-icon color="#1B4332" size="80" class="mb-4">
                mdi-close
              </v-icon>
            <v-card-text>Antes de seguir deberias tener una cuenta, tranquilo es gratis! </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="green darken-1"
                text
                @click="noUserDialog=false"
              >
                Ok
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>

      
    </v-app>
  </div>
</template>
<script src="https://cdn.jsdelivr.net/npm/vue@2.x/dist/vue.js"></script>
<script src="https://cdn.jsdelivr.net/npm/vuetify@2.x/dist/vuetify.js"></script>
<script>
// @ is an alias to /src
import { COLORS } from "@/assets/theme/colors.js";
import navbar from "../components/navbar/navbar.vue";


import Gallery from "../components/home/galeria/galeria.vue";
import FooterOne from "../components/footer/footer.vue";
import DatetimePicker from 'vuetify-datetime-picker'
import swal from "prettyalert";

import {
  getAuth,
} from "firebase/auth";

import { collection, query, where, getDocs, getFirestore, doc, addDoc, deleteDoc, updateDoc    } from "firebase/firestore";

export default {
  name: "Home",
  components: {
    navbar,
    Gallery,
    FooterOne,
    DatetimePicker,
  },
  methods: {
    tryReservation(){
      const auth = getAuth();
      if(auth.currentUser){
        //GO TO RESERVATION
        this.$router.push({ name: 'reservacion' });
      }else{
        //NO USER
        console.log("NO USER");
        this.noUserDialog = true;
      }
    },

    getNoDates(){
      var d = new Date();
      var pred = new Date(d.getFullYear(),d.getMonth()+1,0).getDate();

      var nowd;
      var sat_array = "";
      var sun_array = "";


      for (var i=1;i<=pred;i++) {
        try {
          //console.log(d.getFullYear()+ "-" + (d.getMonth()+1) + "-" + i);
          nowd = new Date(d.getFullYear()+ "-" + (d.getMonth()+1) + "-" + i)

          if (nowd.getUTCDay() == 0)
          {
            if(d.getMonth()+1 <10){
              sat_array = sat_array  + (d.getFullYear()+ "-" + "0"+(d.getMonth()+1) + "-" + i) + ",";
            }else{
              sat_array = sat_array  + (d.getFullYear()+ "-" + (d.getMonth()+1) + "-" + i) + ",";
            }            
              //sat_array = sat_array  + i.toString() + ",";
          }
          if (nowd.getUTCDay() == 6)
          {
            if(d.getMonth()+1 <10){
              sun_array = sun_array  + (d.getFullYear()+ "-" + "0"+(d.getMonth()+1) + "-" + i) + ",";
            }else{
              sun_array = sun_array + (d.getFullYear()+ "-" + (d.getMonth()+1) + "-" + i) + ",";
            }         
              //sun_array = sun_array + i.toString() + ",";
          }

        }
        catch(e) {
            return;
        }
      } 
      this.noDates = sat_array + sun_array;
      //console.log(this.noDates);

    },

    async getEvents () {
      const events = [];
      const db = getFirestore();
      const q = query(collection(db, "citas"));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        //console.log(doc.id, " => ", doc.data());
        events.push(doc.data());
      });
      //console.log(events);
      this.events = events;
    },

    getEventColor (event) {
      return event.color
    },
    rnd (a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a
    },

    async makeCita(){
      if(this.citaNombre != '' && this.citaApellidos != '' && this.citaNumeroTelefono != '' && this.formattedDatetime != ''){
        const db = getFirestore();

        //var tempDate = (this.formattedDatetime).toISOString().slice(0, 19).replace(/-/g, "/").replace("T", " ");

        const now = new Date();
        const offsetMs = now.getTimezoneOffset() * 60 * 1000;
        const dateLocal = new Date(this.formattedDatetime.getTime() - offsetMs);
        var tempDate = dateLocal.toISOString().slice(0, 19).replace(/-/g, "-").replace("T", " ");

        await addDoc(collection(db, "citas"), {
          color: "blue",
          name: this.citaNombre,
          start: tempDate,
          apellidos: this.citaApellidos,
          telefono: this.citaNumeroTelefono,
        });

        swal("Cita agendada", "se ha registrado tu cita, un colaborador se pondra en contacto contigo en caso de cancelacion", "success");

        this.citaNombre = '';
        this.citaApellidos = '';
        this.citaNumeroTelefono = '';
        this.formattedDatetime = '';

        this.getEvents();
      }else{
        swal("Campos sin rellenar", "por favor rellena todos los campos para realizar la solicitud", "error");
      }
      
    },

    allowedDates: function(a){
        //return  val => parseInt(val.split('-')[2], 10) % 2 === 0
        return val => !this.noDates.includes(val);       
        //return val=> console.log(val)   
    },

    allowedHours: function(a){
        return val => !this.noHours.includes(val);  
        //return val=> console.log(val)     
    },

  },
  mounted() {
    this.getNoDates();

    
  },

  computed: {
    cal () {
        return this.ready ? this.$refs.calendar : null
      },
      nowY () {
        return this.cal ? this.cal.timeToY(this.cal.times.now) + 'px' : '-10px'
      },
  },
  data() {
    return {
      colors: COLORS,
      noUserDialog: false,
      nowDate: new Date().toISOString().slice(0,10),
      datepickerSettings: {
        locale: "es",
        allowedDates: this.allowedDates(),
        min: new Date().toISOString().slice(0,10),
        
      },

      timepickerSettings: {
        allowedHours: this.allowedHours(),
        format: "24hr",
      },

      citaNombre: "",
      citaApellidos: "",
      citaNumeroTelefono: "",
      formattedDatetime: "",

      colors2: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
      names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],

      noDates: [],
      noHours: [0,1,2,3,4,5,6,7,8,18,19,20,21,22,23],

      calendarValue: '',
      ready: false,
      name: "",
      events: [],
      mode: 'stack',
      

      images: [
        {
          id: 1,
          src:
            "https://firebasestorage.googleapis.com/v0/b/pedreragarden.appspot.com/o/Media%2FAssets%2F1.jpeg?alt=media&token=631bc3bf-ece6-43ee-9125-6de3aebd03d4",
        },
        {
          id: 2,
          src:
            "https://firebasestorage.googleapis.com/v0/b/pedreragarden.appspot.com/o/Media%2FAssets%2F2.jpeg?alt=media&token=910fa9c9-8a48-40ee-9555-69217220f256",
        },
        {
          id: 3,
          src:
            "https://firebasestorage.googleapis.com/v0/b/pedreragarden.appspot.com/o/Media%2FAssets%2F3.jpeg?alt=media&token=91600430-ad17-4acb-8d87-37d719f6fc3e",
        },
        {
          id: 4,
          src:
            "https://firebasestorage.googleapis.com/v0/b/pedreragarden.appspot.com/o/Media%2FAssets%2F4.jpeg?alt=media&token=a294c610-6385-4a09-8595-84b3dbe3fa13",
        },
        {
          id: 5,
          src:
            "https://firebasestorage.googleapis.com/v0/b/pedreragarden.appspot.com/o/Media%2FJardin%2FJardin5.jpg?alt=media&token=590c6edf-25bd-4677-9019-17d3dfd730e4",
        },
        {
          id: 6,
          src:
            "https://firebasestorage.googleapis.com/v0/b/pedreragarden.appspot.com/o/Media%2FAssets%2FWhatsApp%20Image%202022-05-02%20at%2011.48.20%20AM.jpeg?alt=media&token=bec117e5-9dea-4665-92ef-09572df60596",
        },
        {
          id: 7,
          src:
            "https://firebasestorage.googleapis.com/v0/b/pedreragarden.appspot.com/o/Media%2FAssets%2FWhatsApp%20Image%202022-05-02%20at%2011.47.14%20AM.jpeg?alt=media&token=4e3e83ee-5a8c-43c5-b3da-84eae7e60a79",
        },
        {
          id: 8,
          src:
            "https://firebasestorage.googleapis.com/v0/b/pedreragarden.appspot.com/o/Media%2FAssets%2FWhatsApp%20Image%202022-05-02%20at%2011.42.47%20AM.jpeg?alt=media&token=7464c83a-518c-4bf1-8e59-237e9d12b3dc",
        },
      ],
      features: [
        {
          icon: "mdi-fireplace",
          title: "Instalaciones",
          text: "en Jardín La Pedrera proporcionamos servicios de calidad. Brindamos espacios seguros y funcionales  que pueden ser abiertos o cerrados, según el tipo de clima para tu evento. También te ofrecemos un área de juegos infantiles y área de alberca.",
        },
        {
          icon: "mdi-map-marker-outline",
          title: "Ubicación",
          text: "Nos encontramos a 10 minutos de Plaza Américas. Con el beneficio de estar cerca de la ciudad, pero al mismo tiempo en un espacio verde, reservado y seguro para que disfrutes con tu familia.",
        },
        {
          icon: "mdi-silverware-fork-knife",
          title: "Servicios adicionales",
          text: "¿Falta de tiempo para organizar tu evento? No te desesperes. En Jardín La Pedrera, te apoyamos para armar un paquete a la medida de tus necesidades. Con tan solo unos clicks, selecciona los servicios que más se ajusten a tu evento y presupuesto.",
        },
      ],
      stats: [
        ["30", "Banqueterias"],
        ["5", "Juegos Inflables"],
        ["2", "OtraCosa"],
        ["15", "Ventas Exitosas"],
      ],
    };
  },
};
</script>

<style scoped>

.footer-text{
  font-family: 'Montserrat Alternates', sans-serif!important;
  font-size: 12px!important;
}

.footer-title{
  font-family: 'Poppins', sans-serif!important;
}


.descritive-text {
  color: #081c15;
  font-family: "Poppins", sans-serif !important;
}
.location-text {
  font-size: max(15px) !important;
}
.google-map {
  width: 100% !important;
  height: 450px !important;
}

.float {
  position: fixed;
  width: 30px;
  height: 30px;
  bottom: 20px;
  right: 20px;
  background-color: #0c9;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  box-shadow: 2px 2px 3px #999;
  z-index: +1;
}

.my-float {
  margin-top: 22px;
}

@media only screen and (min-width: 600px) {
  .float {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 40px;
    right: 40px;
    background-color: #0c9;
    color: #fff;
    border-radius: 50px;
    text-align: center;
    box-shadow: 2px 2px 3px #999;
    z-index: +1;
  }

  .my-float {
    margin-top: 22px;
  }
}
.first-line{
  font-family: 'Poppins', sans-serif!important;
  font-weight: 800;
  font-size: 3em;
}

.second-line{
  font-family: 'Poppins', sans-serif!important;
  font-weight: 600;
  font-size: 2em;

}

.v-current-time {
	 height: 2px;
	 background-color: #ea4335;
	 position: absolute;
	 left: -1px;
	 right: 0;
	 pointer-events: none;
}
 .v-current-time.first::before {
	 content: '';
	 position: absolute;
	 background-color: #ea4335;
	 width: 12px;
	 height: 12px;
	 border-radius: 50%;
	 margin-top: -5px;
	 margin-left: -6.5px;
}
</style>
